<template>
  <div class="about">
    <head-nav></head-nav>
    <about-img></about-img>

    <div class="about-wrap">
      <!-- <div class="about-list">
        <h3 class="about-list-title">
          <img
            :src="$t('about.title-1')"
            alt=""
            :style="this.$i18n.locale==='en'?'width:220px;height:auto;':''"
          >
        </h3>

        <div
          class="about-list-content"
          v-html="$t('about.about.list-content-1')"
        >
        </div>

        <div class="about-list-imgs">
          <div
            class="about-list-img"
            v-for="(item, index) in $t('about.about.list-1')"
            :key="index"
          >
            <img
              :src="item.img"
              alt=""
            >
          </div>
        </div>
      </div>

      <div class="about-list">
        <h3 class="about-list-title">
          <img
            :src="$t('about.title-2')"
            alt=""
           :style="this.$i18n.locale==='en'?'width:280px;height:auto;':''"
          >
        </h3>
        <div
          class="about-list-content"
          v-html="$t('about.about.list-content-2')"
        >
        </div>

        <div class="about-list-imgs">
          <div
            class="about-list-img"
            v-for="(item, index) in $t('about.about.list-2')"
            :key="index"
          >
            <img
              :src="item.img"
              alt=""
            >
          </div>
        </div>
      </div> -->

      <div class="about-certificate">
        <h3 class="about-certificate-title">
          <img
            :src="$t('about.title.title-2')"
            alt=""
            :style="this.$i18n.locale==='en'?'width:190px;height:auto;':''"
          >
        </h3>

        <div class="about-certificate-swiper">
          <div class="example-3d">
            <swiper
              class="swiper"
              :options="patentSwiper"
            >
              <swiper-slide
                v-for="(item, index) in patentLists"
                :key="index"
              >
                <img
                  :src="item.image.publicUrl"
                  alt=""
                >

                <div class="swiper-mask">
                  {{item.title}}
                </div>
              </swiper-slide>

            </swiper>
            <div
              class="swiper-pagination"
              slot="pagination"
            ></div>
          </div>
        </div>
      </div>

            <div class="about-certificate">
        <h3 class="about-certificate-title">
          <img
            :src="$t('about.title.title-1')"
            alt=""
            :style="this.$i18n.locale==='en'?'width:190px;height:auto;':''"
          >
        </h3>

        <div class="about-certificate-swiper">
          <div class="example-3d">
            <swiper
              class="swiper"
              :options="certificateSwiper"
            >
              <swiper-slide
                v-for="(item, index) in certificateLists"
                :key="index"
              >
                <img
                  :src="item.image.publicUrl"
                  alt=""
                >

                <div class="swiper-mask">
                  {{item.title}}
                </div>
              </swiper-slide>

            </swiper>
            <div
              class="swiper-pagination"
              slot="pagination"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <footers></footers>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';

import HeadNav from './head-nav';
import AboutImg from './about-img';
import Footers from './footers';

export default {
  components: {
    HeadNav,
    AboutImg,
    Footers,
    Swiper,
    SwiperSlide,
  },
  apollo: {
    certificateList: {
      query() {
        if (this.$i18n.locale === 'cn') {
          return gql`
            query {
              certificateList: allQualificationCertificateIntroductions(orderBy: "id_DESC") {
                content
                qualificationCertificates {
                  image {
                    publicUrl
                  }
                  title
                  abstract
                  order
                }
              }
            }
          `;
        }
        return gql`
          query {
            certificateList: allEnQualificationCertificateIntroductions(orderBy: "id_DESC") {
              content
              qualificationCertificates {
                image {
                  publicUrl
                }
                title
                abstract
                order
              }
            }
          }
        `;
      },
    },
    patentList: {
      query() {
        if (this.$i18n.locale === 'cn') {
          return gql`
            query {
              patentList: allPatentIntroductions(orderBy: "id_DESC") {
                content
                patents {
                  image {
                    publicUrl
                  }
                  title
                  abstract
                  order
                }
              }
            }
          `;
        }
        return gql`
          query {
            patentList: allPatentIntroductions(orderBy: "id_DESC") {
              content
              patents {
                image {
                  publicUrl
                }
                title
                abstract
                order
              }
            }
          }
        `;
      },
    },
  },
  computed: {
    certificateLunbo() {
      if (this.certificateList.length < 1) {
        return {
          content: '',
        };
      }
      return this.certificateList[0];
    },
    patentLunbo() {
      if (this.patentList.length < 1) {
        return {
          content: '',
        };
      }
      return this.patentList[0];
    },
    patentLists() {
      const patentList = this.patentList[0].patents;
      return patentList.sort((a, b) => a.order - b.order);
    },
    certificateLists() {
      const certificateList = this.certificateList[0].qualificationCertificates;
      return certificateList.sort((a, b) => a.order - b.order);
    },
  },
  data() {
    return {
      certificateSwiper: {
        effect: 'coverflow',
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: 'auto',
        spaceBetween: 70,
        coverflowEffect: {
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        },
        pagination: {
          el: '.swiper-pagination',
        },
      },
      patentSwiper: {
        effect: 'coverflow',
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: 'auto',
        spaceBetween: 70,
        coverflowEffect: {
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        },
        pagination: {
          el: '.swiper-pagination',
        },
      },

      patentList: [
        {
          patents: [],
        },
      ],
      certificateList: [
        {
          qualificationCertificates: [],
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.about {
  .about-wrap {
    padding: 40px 30px;
    background: #f8f8f8;

    .about-list {
      &-title {
        margin: 50px 0 30px 0;
        text-align: center;

        img {
          height: 70px;
        }
      }

      &-content {
        line-height: 40px;
        font-size: 28px;
        color: #646464;
      }

      &-imgs {
        margin-top: 30px;
        width: 100%;
        display: flex;
        flex: 1;
        flex-wrap: wrap;
        justify-content: space-between;
        border-radius: 10px;
        overflow: hidden;

        .about-list-img {
          width: 344.5px;

          &:nth-child(1) {
            width: 100%;
          }

          img {
            width: 100%;
            height: 300px;
          }
        }
      }
    }

    .about-certificate {
      padding-bottom: 100px;

      &-title {
        margin: 50px 0 30px 0;
        text-align: center;

        img {
          height: 70px;
        }
      }

      &-swiper {
        .example-3d {
          width: 100%;
          height: 560px;
          padding: 10px 0;
        }

        .swiper {
          height: 100%;
          width: 100%;

          .swiper-slide {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 280px;
            height: 390px;
            text-align: center;
            font-weight: bold;
            font-size: 28px;
            background-position: center;
            background-size: cover;
            color: white;
            position: relative;

            img {
              width: 280px;
              height: 390px;
            }

            .swiper-mask {
              position: absolute;
              bottom: 0px;
              left: 0;
              z-index: 2;
              width: 100%;
              font-size: 28px;
              color: #fff;
              font-weight: 100;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              background: #000;
              opacity: 0.8;
              height: 60px;
              line-height: 60px;
            }
          }
        }

        ::v-deep .swiper-pagination {
          // left: 30%;
          // text-align: center;
          margin: 0 auto;
          position: static !important;

          .swiper-pagination-bullet.swiper-pagination-bullet-active {
            background-color: #ffbf00;
          }

          .swiper-pagination-bullet {
            margin: 0 15px;
            width: 20px;
            height: 20px;
            background: #999999;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 320px) {
  .about .about-wrap .about-list-imgs .about-list-img {
    width: 288px;
  }
}
</style>
